<div class="page-container">
  <div class="hero-picture-container items-center justify-center flex" style="height: 100vh">
<!--    <img src="/assets/images/gift/brad-background-mobile.jpg" alt="Hero banner" class="hero-picture-mobile" />-->
<!--    <img src="/assets/images/gift/brad-background-desktop.jpg" alt="Hero banner" class="hero-picture-desk" />-->
    <div class="hero-picture-content">
      <h1 class="hero-title">Succulent Studios <br> BOGO </h1>
      <div class="heart-row">
        <span class="heart-line"></span>
        <img class="heart-black-icon" src="/assets/images/heart-black.png" alt="">
        <span class="heart-line"></span>
      </div>
      <h1 class="hero-subtitle">
        <!-- <span class="hero-title-bold">Succulent Gift Box BOGO</span> -->
        <b>GIFT SUCCULENTS. <br> GET SUCCULENTS.</b>
      </h1>
    </div>
  </div>
  <section class="page-body">
    <div class="description-section">
      <h3 class="description-section-subtitle">Give A Gift Subscription, Get a Box Just For You!</h3>
      <h2 class="description-section-title">Just $12/month +shipping.</h2>

      <div class="description__item-section">

        <div class="description__item description__item-1">
          <div class="description__picture-container">
            <img src="/assets/images/gift-icon.png" alt="Gift icon">
          </div>
          <div class="description__item__title">You gift.</div>
          <div class="description__item__text">Tell us who gets the pleasure of receiving your gift, pick the shipment date, and write them
            <br> a suc-CUTE-lent note.</div>
        </div>

        <div class="description__item description__item-2">
          <div class="description__picture-container">
            <img src="/assets/images/truck-icon.png" alt="Truck icon">
          </div>
          <div class="description__item__title">We ship.</div>
          <div class="description__item__text">Set a shipment date, and we'll send a pair of unique, 8-week-old succulents right to their doorstep.</div>
        </div>

        <div class="description__item description__item-3">
          <div class="description__picture-container">
            <img src="/assets/images/plant-icon.jpg" alt="Plant icon">
          </div>
          <div class="description__item__title">They enjoy.</div>
          <div class="description__item__text">(And hopefully send you a good old fashioned thank-you card!)</div>
        </div>

      </div>

    </div>

    <div class="plans-cards-container">
      <h1 class="plans-container-title">Choose A Gift Plan</h1>
      <div class="plans-cards">
        <div routerLink="/checkout" [queryParams]="{ planId: PLAN_3M_GIFT, bogo: true }"
             class="plan-card mb-4 cursor-pointer">
          <img src="/assets/images/plans/3m.png">
        </div>

        <div routerLink="/checkout" [queryParams]="{ planId: PLAN_6M_GIFT, bogo: true }"
             class="plan-card mb-4 cursor-pointer relative">
          <img src="/assets/images/plans/6m.png">
          <img src="/assets/images/plans/most-popular.svg" class="absolute"
               style="left: 50%; transform: translateX(-50%); top: -15px;">
        </div>

        <div routerLink="/checkout" [queryParams]="{ planId: PLAN_12M_GIFT, bogo: true }"
             class="plan-card mb-4 cursor-pointer">
          <img src="/assets/images/plans/12m.png">
        </div>
      </div>
      <p class="description-text">
        *Your BOGO includes one 3, 6, or 12-month gift subscription, plus a one-time box of succulents as a bonus for you! Your box of succulents will typically ship near when your gift recipient's first box ships.
      </p>
    </div>

    <div class="attributes-section">

      <div class="attribute__item">
        <div class="attribute__picture-circle">
          <img src="/assets/images/jar.png" alt="Jar icon" />
        </div>
        <h3 class="attribute__title">Drought-tolerant</h3>
        <p class="attribute__text">Conserve water! Your succulents only need a drink every 7-10 days.</p>
      </div>

      <div class="attribute__item">
        <div class="attribute__picture-circle">
          <img src="/assets/images/sun.png" alt="Sun icon" />
        </div>
        <h3 class="attribute__title">Easy Care</h3>
        <p class="attribute__text">Set in a spot that gets about 6+ hours of natural light a day.</p>
      </div>

      <div class="attribute__item">
        <div class="attribute__picture-circle">
          <img src="/assets/images/oxygen.png" alt="Oxygen icon" />
        </div>
        <h3 class="attribute__title">Oxygen-boosters</h3>
        <p class="attribute__text">Succulents release oxygen all night long while your other plants release carbon dioxide.</p>
      </div>

      <div class="attribute__item">
        <div class="attribute__picture-circle">
          <img src="/assets/images/leaf.png" alt="Leaf icon" />
        </div>
        <h3 class="attribute__title">Low Pollen Production</h3>
        <p class="attribute__text">Most succulents and all cacti are a 1 on the Ogren Plant Allergy Scale (OPALS), so they make perfect houseplants
          for those who suffer from allergies.</p>
      </div>

    </div>

  </section>
</div>
